import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { TweenMax, TimelineMax } from 'gsap/TweenMax'
import Reveal from 'react-reveal'
import { common } from '../utility/common'
import theme from '../utility/theme'
import media from '../utility/media'
import Tag from '../components/page-components/PageTag'
import Title from '../components/page-components/Title'
import SocialShare from '../components/page-components/blog/SocialShare'
import RealtedItems from '../components/page-components/blog/RealtedItems'

const Container = styled.div`
  width: 100%;
  color: ${theme.colorSecondary};
  padding-bottom: 65px;
`

const StyledHeader = styled.div`
  width: 100%;
`

const BlogDate = styled.div`
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  margin-top: 15px;
`

const ContentContainer = styled.div``

const ContentDiv = styled.div`
  padding-top: 30px;
  & h3 {
    font-size: 25px;
    margin-top: 30px;
    margin-bottom: 15px;
    color: #fff;
    @media (max-width: ${media.md}) {
      font-size: 18px;
    }
  }
  & h4 {
    font-size: 22px;
    margin-top: 30px;
    margin-bottom: 15px;
    color: #fff;
    @media (max-width: ${media.md}) {
      font-size: 16px;
    }
  }
  & h5 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
    color: #fff;
    @media (max-width: ${media.md}) {
      font-size: 15px;
    }
  }
  & p {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: ${theme.colorBlog};
    overflow-x: auto;
    padding-bottom: 10px;
    &:first-child {
      margin-top: 30px;
    }
  }
  & div[data-oembed] {
    margin-top: 30px;
    margin-bottom: 15px;
    & iframe {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: auto;
      @media (min-width: ${media.sm}) {
        max-width: 100%;
        max-height: 100%;
        height: 350px;
      }
      @media (min-width: ${media.md}) {
        max-width: 100%;
        max-height: 100%;
        height: 400px;
      }
      @media (min-width: ${media.xl}) {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 500px;
      }
    }
  }
  & pre {
    border-left: 10px solid ${theme.colorGrey};
    padding: 15px;
    color: #fff;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  & img {
    max-width: 100%;
    max-height: 100%;
    margin-top: 30px;
    margin-bottom: 15px;
  }
`

const Divider = styled.div`
  height: 5px;
  background: ${theme.colorGrey};
  margin: 30px 0 20px 0;
`

const ShareDivContainer = styled.div``

const ShareDiv = styled.div`
  padding-bottom: 20px;
`

const RelatedDivContainer = styled.div``

const ShareTitle = styled.h5`
  font-family: ${theme.fontFamily};
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
`

const RelatedDiv = styled.div`
  padding-bottom: 20px;
`

class BlogSingle extends Component {
  componentDidMount() {
    TweenMax.from(this.blogDate, common.duration, {
      autoAlpha: 0,
      x: -25,
      ease: common.easing.easeOut,
      delay: this.props.timingOffset + 0.6,
    })

    TweenMax.from(this.contentContainer, common.duration, {
      autoAlpha: 0,
      x: -25,
      ease: common.easing.easeOut,
      delay: this.props.timingOffset + 0.8,
    })
  }

  animateContentDiv = () => {
    TweenMax.from(this.contentDiv, common.duration * 2, {
      autoAlpha: 0,
      x: -25,
      ease: common.easing.easeOut,
    })
  }

  animateShareDiv = () => {
    TweenMax.from(this.shareDivContainer, common.duration * 3, {
      autoAlpha: 0,
      x: -25,
      ease: common.easing.easeOut,
      delay: common.duration,
    })
  }

  animateRelatedDiv = () => {
    TweenMax.from(this.relatedDivContainer, common.duration * 4, {
      autoAlpha: 0,
      x: -25,
      ease: common.easing.easeOut,
      delay: common.duration,
    })
  }

  render() {
    const { data, timingOffset, pageContext, ...rest } = this.props
    const dataEdges = this.props.data.allPrismicBlogPost.edges

    const currentBlogArray = dataEdges.filter(item => {
      return item.node.uid === pageContext.slug
    })

    //Basic data
    const currentNode = currentBlogArray[0].node
    const currentUid = currentNode.uid

    //Data from node data
    const nodeData = currentBlogArray[0].node.data
    const currentCategory = nodeData.category
    const date = nodeData.date
    const html = nodeData.content.html

    //For getting related data
    let counter = 0
    const relatedDataEdges = dataEdges.filter(item => {
      const itemUid = item.node.uid
      const itemCategory = item.node.data.category
      const flag = itemCategory === currentCategory

      // If current category && at most 2 items && not current item
      if (flag && counter <= 1 && itemUid !== currentUid) {
        counter++
        return flag
      }
    })

    return (
      <>
        <Helmet>
          <title>{nodeData.title.text}</title>
          <meta name="description" content={nodeData.content.text.substring(0,160)} />
        </Helmet>
        <Container>
          <StyledHeader>
            <Tag delay={timingOffset}>blog</Tag>
            <Title delay={timingOffset + 0.3}>{nodeData.title.text}</Title>
            <BlogDate innerRef={div => (this.blogDate = div)}>{date}</BlogDate>
          </StyledHeader>
          <RelatedDiv innerRef={div => (this.contentContainer = div)}>
            <Reveal onReveal={this.animateContentDiv}>
              <ContentDiv
                dangerouslySetInnerHTML={{ __html: html }}
                innerRef={div => (this.contentDiv = div)}
              />
            </Reveal>
            <Reveal onReveal={this.animateShareDiv}>
              <ShareDivContainer
                innerRef={div => (this.shareDivContainer = div)}
              >
                <Divider />
                <ShareDiv>
                  <ShareTitle>Share this</ShareTitle>
                  <SocialShare />
                </ShareDiv>
              </ShareDivContainer>
            </Reveal>

            <Reveal onReveal={this.animateRelatedDiv}>
              <RelatedDivContainer
                innerRef={div => (this.relatedDivContainer = div)}
              >
                <Divider />
                <RelatedDiv>
                  <RealtedItems relatedData={relatedDataEdges} {...rest} />
                </RelatedDiv>
              </RelatedDivContainer>
            </Reveal>
          </RelatedDiv>
        </Container>
      </>
    )
  }
}

export const query = graphql`
  query {
    allPrismicBlogPost {
      edges {
        node {
          uid
          data {
            category
            title {
              text
            }
            date(formatString: "MMMM DD, YYYY")
            content {
              html
              text
            }
          }
        }
      }
    }
  }
`

export default BlogSingle
