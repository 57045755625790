import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons'
import theme from '../../../utility/theme'
import media from '../../../utility/media'

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  @media (max-width: ${media.sm}) {
    flex-direction: column;
    justify-content: space-between;
    align-items: center
  }
`

const ShareLink = styled.a`
  display: inline-block;
  font-family: ${theme.fontFamily};
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 30px;
  color: #fff !important;
  background: ${({ background }) => background};
  margin-right: 20px;
  border: none;
  outline: none;
  padding: 7px 20px;
  cursor: pointer;
  @media (max-width: ${media.sm}) {
    width: 80%;
    margin: 5px 0;
  }
`
const StyledFa = styled(FontAwesomeIcon)`
  display: inline-block;
  font-size: 18px;
`
const Text = styled.span`
  display: inline-block;
  margin-left: 15px;
`

const fbShare = `
  https://www.facebook.com/sharer/sharer.php?u= ${typeof window !== `undefined` ? window.location.href : null}
`

const tTShare = `
  https://twitter.com/home?status=${typeof window !== `undefined` ? window.location.href : null}
`

const ldShare = `
  https://www.linkedin.com/shareArticle?mini=true&url=${typeof window !== `undefined` ? window.location.href : null}&title=Awesome%20Article&summary=&source=
`

const SocialShare = () => (
  <Container>
    <ShareLink onClick={() => {
      window.open(fbShare, '_blank', 'location=yes,height=670,width=620,scrollbars=yes,status=yes')
    }} background="#3A579A">
      <StyledFa icon={faFacebookF} />
      <Text>Share</Text>
    </ShareLink>
    <ShareLink onClick={() => {
      window.open(tTShare, '_blank', 'location=yes,height=670,width=620,scrollbars=yes,status=yes')
    }} background="#00ABF0">
      <StyledFa icon={faTwitter} />
      <Text>Tweet</Text>
    </ShareLink>
    <ShareLink onClick={() => {
      window.open(ldShare, '_blank', 'location=yes,height=670,width=620,scrollbars=yes,status=yes')
    }} background="#0077B5">
      <StyledFa icon={faLinkedinIn} />
      <Text>LinkedIn</Text>
    </ShareLink>
  </Container>
)

export default SocialShare
