import React from 'react'
import styled from 'styled-components'
import theme from '../../../utility/theme'
import media from '../../../utility/media'

const Container = styled.div`
  display: flex;
  @media (max-width: ${media.lg}) {
    flex-direction: column;
  }
`
const RelatedTitle = styled.h5`
  font-family: ${theme.fontFamily};
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
`

const RelatedItem = styled.div`
  color: #fff !important;
  background: ${theme.colorGrey};
  padding: 50px;
  width: 47%;
  &:nth-child(even) {
    margin-left: 3%;
  }
  &:nth-child(odd) {
    margin-right: 3%;
  }
  @media (max-width: ${media.lg}) {
    width: 100%;
    margin: 10px 0 !important;
    padding: 20px;
  }
`

const StyledLink = styled.a`
  display: block;
  height: 100%;
`

const Title = styled.p`
  font-size: 24px;
  color: #fff;
  margin-bottom: 10px;
  @media (max-width: ${media.lg}) {
    font-size: 18px;
  }
`
const DateText = styled.p`
  font-size: 14px;
  font-weight: 500px;
  color: ${theme.colorSecondary};
`

const RelatedItems = ({ relatedData, startPageChangingHandler, ...rest }) => {
  const relatedDataEdges = relatedData

  const relatedItems = relatedDataEdges.map((edge, index) => {
    const slug = '/blog/' + edge.node.uid
    const title = edge.node.data.title.text
    const date = edge.node.data.date
    return (
      <RelatedItem key={index}>
        <StyledLink
          href={slug}
          onClick={e => {
            e.preventDefault()
            startPageChangingHandler(slug)
          }}
        >
          <Title>{title}</Title>
          <DateText>{date}</DateText>
        </StyledLink>
      </RelatedItem>
    )
  })

  return relatedDataEdges.length > 0 ? (
    <>
      <RelatedTitle>You might enjoy these too</RelatedTitle>
      <Container> {relatedItems}</Container>
    </>
  ) : null
}

export default RelatedItems
